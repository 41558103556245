import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpClientService {

  private httpClient = inject(HttpClient);
  private headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });

  private readonly hostName = environment.hostName;
  private readonly restAPI = environment.restAPI;
  private readonly authServiceHost = environment.authServiceHost;
  private readonly authServiceRestApiV1 = environment.authServiceRestApiV1;

  get(path: string): Observable<any> {
    return this.httpClient.get(this.hostName + this.restAPI + path, {});
  }

  post(path: string, body: any | null): Observable<any> {
    return this.httpClient.post(this.hostName + this.restAPI + path, body, { headers: this.headers });
  }

  authServicePost(path: string, body: any | null): Observable<any> {
    return this.httpClient.post(this.authServiceHost + this.authServiceRestApiV1 + path, body, {});
  }

  authServiceGet(path: string): Observable<any> {
    return this.httpClient.get(this.authServiceHost + this.authServiceRestApiV1 + path, {});
  }
}
