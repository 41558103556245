import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { INTL_TEL_INPUT } from '@constants/app.constants';
import { UserIpDataModel } from '@models/user-ip-data.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  readonly ipLookupUrl = INTL_TEL_INPUT.IPLOOKUP_URL;
  private httpClient = inject(HttpClient);

  sortBy(collection: Array<any>, property: string): Array<any> {
    collection.sort((a: any, b: any) => {
      if ((a[property] ? a[property].toLowerCase() : undefined) < (b[property] ? b[property].toLowerCase() : undefined)) {
        return -1;
      } else if (a[property] > b[property]) {
        return 1;
      } else {
        return 0;
      }
    });
    return collection;
  }

  getUserCountry(): Observable<UserIpDataModel> {
    return this.httpClient.get<UserIpDataModel>(this.ipLookupUrl);
  }
}
