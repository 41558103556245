export const environment = {
  version: '7.2.1',
  production: false,
  hostName: 'https://dev.my.calenso.com',
  restAPI: '/api/v2/',
  authServiceHost: 'https://dev.auth.calenso.com',
  authServiceRestApiV1: '/api/',
  encryptedKey: 'dfk34Sfa$UzthV3sFs9S=fsla357UiTw$',
  imagePath: 'https://dev.my.calenso.com/files/workers/avatar/',
  partnersImagePath: 'https://dev.my.calenso.com/files/partners/logo/',
  deployUrl: 'https://signup.calenso.com',
  redirectDomain: 'https://calio.calenso.com',
  googleTagManagerCode: 'GTM-MJC5XGQ',
  recaptchaSiteKey: '6LfB5EchAAAAAMiJapMV9GumBAQBfKqboRWT5she'
};
