import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'addRequired',
  standalone: true
})
export class AddRequiredPipe implements PipeTransform {

  transform(value: string, args?: any): any {
    if (!value) {
      return null;
    }

    return value.trim() + ' *';
  }
}
