@if (!accountCreated) {
  <div class="partner-form">
    <form class="row form-group" #partnerForm="ngForm">
      <!-- first name -->
      <div class="col-sm-6 calio-pc-reseller-form-group mb-2">
        <label class="calio-reseller-label" for="prename">
          {{ 'create_partner_component.prename_label' | translate | addRequired }}
        </label>
        <input
          type="text"
          class="reseller-form-input"
          id="prename"
          name="prename"
          [(ngModel)]="partnerFormModel.prename"
          #prename="ngModel"
          [ngClass]="{ 'is-invalid': prename.touched && prename.invalid }"
          required="true"
          [maxlength]="255"
        />
        @if (prename.touched && prename.invalid) {
          <div class="calio-pc-reseller-invalid-feedback">
            @if (prename.errors.required) {
              {{ 'error_messages.required_error_message' | translate }}
            }
          </div>
        }
      </div>
      <!-- last name -->
      <div class="col-sm-6 calio-pc-reseller-form-group mb-2">
        <label class="calio-reseller-label" for="lastname">
          {{ 'create_partner_component.lastname_label' | translate | addRequired }}
        </label>
        <input
          type="text"
          class="reseller-form-input"
          id="lastname"
          name="lastname"
          [(ngModel)]="partnerFormModel.lastname"
          #lastname="ngModel"
          [ngClass]="{ 'is-invalid': lastname.touched && lastname.invalid }"
          required="true"
          [maxlength]="255"
        />
        @if (lastname.touched && lastname.invalid) {
          <div class="calio-pc-reseller-invalid-feedback">
            @if (lastname.errors.required) {
              {{ 'error_messages.required_error_message' | translate }}
            }
          </div>
        }
      </div>
      <!-- Company name -->
      <div class="col-sm-6 calio-pc-reseller-form-group mb-2">
        <label class="calio-reseller-label" for="Company name">
          {{ 'create_partner_component.company_name' | translate | addRequired }}
        </label>
        <input
          type="text"
          class="reseller-form-input"
          id="name"
          name="name"
          [(ngModel)]="partnerFormModel.name"
          #name="ngModel" [ngClass]="{ 'is-invalid': name.touched && name.invalid }"
          required="true"
          [maxlength]="255"
        />
        @if (name.touched && name.invalid) {
          <div class="calio-pc-reseller-invalid-feedback">
            @if (name.errors.required) {
              {{ 'error_messages.required_error_message' | translate }}
            }
          </div>
        }
      </div>
      <!-- Industry name -->
      <div class="col-sm-6 custom-ng-select mb-2">
        <label class="calio-reseller-label mb-2" for="industry">
          {{ 'create_partner_component.industry' | translate | addRequired }}
        </label>
        <ng-select
          id="industry"
          name="industry"
          [(ngModel)]="partnerFormModel.industry"
          #industry="ngModel"
          [ngClass]="{ 'is-invalid': industry.touched && industry.invalid }"
          required="true"
        >
          @for (industry of (industryList | staticTranslationSort : 'key'); track industry) {
            <ng-option [value]="industry.value">
              {{ industry.key | translate }}
            </ng-option>
          }
        </ng-select>
        @if (industry.touched && industry.invalid) {
          <div class="calio-pc-reseller-invalid-feedback">
            @if (industry.errors.required) {
              {{ 'error_messages.required_error_message' | translate }}
            }
          </div>
        }
      </div>
      <!-- Compnay Size -->
      <div class="col-sm-6 custom-ng-select mb-2">
        <label class="calio-reseller-label mb-2" for="company size">
          {{ 'create_partner_component.company_size' | translate | addRequired }}
        </label>
        <ng-select
          id="companySize"
          name="companySize"
          [(ngModel)]="partnerFormModel.company_size"
          #companysize="ngModel"
          [ngClass]="{ 'is-invalid': companysize.touched && companysize.invalid }"
          required="true"
        >
          @for (size of companySize; track size) {
            <ng-option [value]="size.value">
              {{ 'create_partner_component.company_size_options.' + size.key | translate }}
            </ng-option>
          }
        </ng-select>
        @if (companysize.touched && companysize.invalid) {
          <div class="calio-pc-reseller-invalid-feedback">
            @if (companysize.errors.required) {
              {{ 'error_messages.required_error_message' | translate }}
            }
          </div>
        }
      </div>
      <!-- Phone -->
      <div class="col-sm-6 calio-pc-reseller-form-group mb-2">
        <label class="calio-reseller-label" for="phone">
          {{ 'create_partner_component.phone' | translate }}
        </label>
        <div class="mt-2">
          <app-calio-tel-input
            name="phone"
            [(ngModel)]="partnerFormModel.phone"
            #phone="ngModel"
            cssClass="reseller-form-input"
            [ngClass]="{ 'is-invalid': phone?.touched && phone?.errors }"
          />
        </div>
        @if (phone?.touched && phone?.invalid) {
          <div class="calio-pc-reseller-invalid-feedback">
            @if (phone?.errors?.invalidNumber) {
              {{ 'create_partner_component.invalid_phone_number' | translate }}
            }
          </div>
        }
      </div>
      <div class="col-md-12 calio-pc-reseller-form-group">
        <div class="calio-pc-reseller-form-group mb-4">
          <label class="calio-reseller-label" for="password">
            {{ 'create_partner_component.password_label' | translate | addRequired }}
          </label>
          <input
            type="{{ passwordType }}"
            class="reseller-form-input"
            id="password"
            name="password"
            [(ngModel)]="partnerFormModel.password"
            #password="ngModel"
            [ngClass]="{ 'is-invalid': password.touched && password.invalid }"
            required="true" #passwordWithValidation
            (blur)="showPasswordDetails = false"
            (keyup)="showPasswordDetails = true"
            minlength="8"
            [maxlength]="255"
          />
          <app-password-strength-bar
            [passwordToCheck]="partnerFormModel.password"
            (passwordValidatedEvent)="validatePassword($event)"
          />
          @if (!isPasswordValid && partnerFormModel.password) {
            <div class="calio-pc-reseller-invalid-feedback">
              {{ 'create_partner_component.password_strength_error' | translate }}
            </div>
          }
          @if (password.touched && password.invalid) {
            <div class="calio-pc-reseller-invalid-feedback">
              @if (password.errors.required) {
                {{ 'error_messages.required_error_message' | translate }}
              }
            </div>
          }
        </div>
      </div>
      @if (!accountCreated) {
      <div class="col-md-12 mb-1 calio-pc-button-container">
        <button
          id="register_company_data"
          type="submit"
          [disabled]="partnerForm.invalid || !isPasswordValid"
          class="calio-pc-reseller-button signin-button"
          (click)="savePartnerData()"
        >
          @if (showText) {
            <span>
              {{ 'create_partner_component.create_account' | translate }}
            </span>
          }
          @if (showLoader) {
            <div class="lds-ring">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          }
        </button>
      </div>
      }
      <div class="fs-6 m-2 text-center free-account-text">
        {{ 'create_partner_component.free_account' | translate }}
      </div>
    </form>
  </div>
}