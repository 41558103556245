import { NgClass } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { environment } from '@environments/environment';
import { TranslateModule } from '@ngx-translate/core';
import { AddRequiredPipe } from '@pipe/add-required.pipe';

@Component({
  selector: 'app-email-step',
  templateUrl: './email-step.component.html',
  standalone: true,
  imports: [FormsModule, NgClass, TranslateModule, AddRequiredPipe],
})
export class EmailStepComponent {

  @Input('emailFormError') set emailForm(reset: boolean) {
    reset && this.resetEmailForm();
  };

  @Output() protected onNextEvent = new EventEmitter<string>();

  protected readonly redirectUrl = environment.redirectDomain;

  protected emailAddress: string;
  protected showLoader = false;

  private resetEmailForm(): void {
    this.showLoader = false;
  }

  protected nextStep(): void {
    this.showLoader = true;
    this.onNextEvent.emit(this.emailAddress);
  }
}
