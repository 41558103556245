import { NgClass } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CalioTelInputComponent } from '@calio-lib/calio-intl-tel-input/calio-tel-input/calio-tel-input.component';
import { PasswordStrengthBarComponent } from '@components/password-strength-bar/password-strength-bar.component';
import { COMPANY_SIZE, INDUSTRY_LIST } from '@constants/app.constants';
import { environment } from '@environments/environment';
import { PartnerFormModel } from '@models/partner-form.model';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { AddRequiredPipe } from '@pipe/add-required.pipe';
import { StaticTranslationSortPipe } from '@pipe/static-translation-sort.pipe';
import { HelperService } from '@services/helper.service';
import { LoggerService } from '@services/logger.service';

@Component({
  selector: 'app-personal-data-step',
  templateUrl: './personal-data-step.component.html',
  styleUrls: ['./personal-data-step.component.scss'],
  standalone: true,
  imports: [FormsModule, NgClass, NgSelectModule, CalioTelInputComponent, PasswordStrengthBarComponent, TranslateModule, AddRequiredPipe, StaticTranslationSortPipe]
})
export class PersonalDataStepComponent implements OnInit {

  @Input() email: string;
  @Input('personalFormError') set personalForm(reset: boolean) {
    reset && this.resetPersnalDataForm();
  };
  @Output() onNextEvent = new EventEmitter<PartnerFormModel>();

  private helperService = inject(HelperService);

  readonly redirectUrl = environment.redirectDomain;
  readonly industryList = INDUSTRY_LIST;
  readonly companySize = COMPANY_SIZE;

  partnerFormModel = new PartnerFormModel();
  showPasswordDetails: boolean;
  passwordType = 'password';
  accountCreated = false;
  emailAddressError = false;
  errorOccured = false;
  isFrame: boolean;
  showLoader = false;
  showText = true;
  isPasswordValid = false;
  passwordStrengthScore = 0;

  ngOnInit(): void {
    this.getUserCountry();
  }

  resetPersnalDataForm(): void {
    this.showLoader = false;
    this.showText = true;
  }

  togglePasswordType(): void {
    this.passwordType = (this.passwordType === 'password') ? 'text' : 'password';
  }

  getUserCountry(): void {
    this.helperService.getUserCountry().subscribe({
      next: userdata => {
        if (userdata?.country) {
          this.partnerFormModel.country_identifier = userdata.country.toLowerCase();
          this.partnerFormModel.timezone = userdata.timezone;
          this.partnerFormModel.country_code = userdata.country;
        }
      },
      error: (error: HttpErrorResponse) => {
        LoggerService.warn("IP lookup service throwing error");
        LoggerService.error(error);
      }
    })
  }

  savePartnerData(): void {
    this.showLoader = true;
    this.showText = false;

    const result: PartnerFormModel = {
      name: this.partnerFormModel.name,
      email: this.email,
      prename: this.partnerFormModel.prename,
      lastname: this.partnerFormModel.lastname,
      password: this.partnerFormModel.password,
      password_repeat: this.partnerFormModel.password,
      country_identifier: this.partnerFormModel.country_identifier,
      country_code: this.partnerFormModel.country_code,
      timezone: this.partnerFormModel.timezone,
      industry: this.partnerFormModel.industry,
      company_size: this.partnerFormModel.company_size,
      phone: this.partnerFormModel?.phone || undefined
    };
    this.onNextEvent.emit(result);
  }

  validatePassword(event: { score: number, idx: number, col: string }): void {
    this.isPasswordValid = (event.score >= 90);
  }
}
