import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit, inject } from '@angular/core';
import { GTM_EVENT_CONSTANT } from '@constants/app.constants';
import { environment } from '@environments/environment';
import { TranslateModule } from '@ngx-translate/core';
import { CalioSafePipe } from "@pipe/calio-safe.pipe";
import { AnalyticsService } from '@services/analytics.service';
import { HttpClientService } from '@services/http-client.service';

@Component({
  selector: 'app-final-step',
  templateUrl: './final-step.component.html',
  styleUrls: ['./final-step.component.scss'],
  standalone: true,
  imports: [TranslateModule, CalioSafePipe]
})
export class FinalStepComponent implements OnInit {

  @Input() protected partner: any;

  private analyticsService = inject(AnalyticsService);
  private httpClientService = inject(HttpClientService);

  protected readonly gtmEventConstant = GTM_EVENT_CONSTANT;
  protected readonly redirectDomain = environment.redirectDomain;

  protected isRessendEmailSend = false;
  protected remainingTime = 60;
  protected blockForTimeMinutes: number;
  protected blockForTimeSeconds: number;
  protected statusMessage: string;

  ngOnInit(): void {
    this.analyticsService.emitGTMEvent(this.gtmEventConstant.STEP_3);
  }

  protected resendMail(): void {
    this.httpClientService.authServicePost(`partners/send-verification-email/${this.partner.uuid}`, {}).subscribe({
      next: result => {
        if (result?.status) {
          this.isRessendEmailSend = true;
          this.startCountDown();
        }
      },
      error: (error: HttpErrorResponse) => {
        const errorState = error?.error?.error;

        // User will be block, if he makes too many requests
        if (errorState === 'TooManyRequestsException') {
          if (error.headers.get('Retry-After')) {
            const retryAfter = +error.headers.get('Retry-After');
            if (retryAfter > 60) {
              this.blockForTimeMinutes = Math.floor(retryAfter / 60);
            } else {
              this.blockForTimeSeconds = +error.headers.get('Retry-After');
            }
          } else {
            this.statusMessage = 'TooManyRequestsException'.toLowerCase();
          }
          return;
        }

        this.statusMessage = 'SomeThingWentWrong'.toLowerCase();
        if ([
          'BadRequestException',
          'IpNotWhitelistedException',
          'ResendLimitReachedException'
        ].includes(errorState)) {
          this.statusMessage = errorState.toLowerCase();
        }
      }
    });
  }

  protected startCountDown(): void {
    setTimeout(() => {
      if (this.remainingTime < 2) {
        this.remainingTime = 60;
        this.isRessendEmailSend = false;
      } else {
        this.remainingTime--;
        this.startCountDown();
      }
    }, 1000)
  }
}
