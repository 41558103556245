import { KeyValue } from '@angular/common';
import { Pipe, PipeTransform, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'staticTranslationSort',
  standalone: true
})
export class StaticTranslationSortPipe implements PipeTransform {

  private translateService = inject(TranslateService)

  transform(list: KeyValue<string, string>[], sortKey: string) {
    try {
      return this.sortBy(list, sortKey);
    } catch (e) {
      return list;
    }
  }

  sortBy(collection: KeyValue<string, string>[], property: string) {
    const translatedValuesMap = new Map<string, string>();
    collection.forEach(item => {
      const translatedValue = translatedValuesMap.get(item[property]);
      if (!translatedValue) {
        const translated = this.translateService.instant(item[property]);
        translatedValuesMap.set(item[property], translated);
      }
    });

    collection.sort((previous, current) => {
      const translatedPrevious = translatedValuesMap.get(previous[property])?.toLowerCase();
      const translatedCurrent = translatedValuesMap.get(current[property])?.toLowerCase();

      if (translatedPrevious < translatedCurrent) {
        return -1;
      } else if (translatedPrevious > translatedCurrent) {
        return 1;
      } else {
        return 0;
      }
    });

    return collection;
  }
}
