<form class="form-group" #emailForm="ngForm">
  <div class="calio-pc-reseller-form-group mb-2">
    <label class="calio-reseller-label" for="email">
      {{ 'create_partner_component.email_label' | translate | addRequired }}
    </label>
    <input
      email
      type="email"
      class="reseller-form-input"
      id="email"
      name="email"
      [(ngModel)]="emailAddress"
      #email="ngModel"
      [ngClass]="{ 'is-invalid': email.touched && email.invalid }"
      required="true"
      placeholder="name@company.com"
      [maxlength]="255"
    />
    @if (email.touched && email.invalid) {
      <div class="calio-pc-reseller-invalid-feedback">
        @if (email?.errors?.required) {
          {{ 'error_messages.required_error_message' | translate }}
        } @else if (email?.errors?.email) {
          {{ 'error_messages.email_error_message' | translate }}
        }
      </div>
    }
  </div>

  <div class="col-md-12 calio-pc-button-container mt-4">
    <button
      id="register_start"
      type="submit"
      [disabled]="email.invalid"
      class="calio-pc-reseller-button signin-button"
      (click)="nextStep()"
    >
      @if (!showLoader) {
        <span>{{ 'create_partner_component.continue' | translate }}</span>
      } @else {
        <div class="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      }
    </button>
  </div>
</form>

<div class="forgot-password mt-3 d-flex">
  {{ 'create_partner_component.login' | translate }}&nbsp;
  <a class="forgot-password" href="{{ redirectUrl }}" target="_blank" data-cy="login-passwort-reset-link">
    {{ 'create_partner_component.login_link' | translate }}
  </a>
</div>